import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "../images/brandLogos/Primary.png"
import { useScrollYPosition } from "react-use-scroll-position"
import SocialMedia from "./SocialMedia"
import OrderOnline from "./OrderOnline"

const Header = ({ homeNav }) => {
  const [change, setChange] = useState(false)
  const [position, setPosition] = useState(0)

  const scrollY = useScrollYPosition()

  useEffect(() => {
    if (document.getElementById("next")) {
      setPosition(document.getElementById("next").offsetTop)
    }
  }, [position])

  if (scrollY > position && !change) {
    setChange(true)
  }
  if (scrollY <= position && change) {
    setChange(false)
  }

  let style = {
    top: change ? "0" : "-100px",
    transition: "300ms ease",
  }

  return (
    <header className="header" style={homeNav ? style : null}>
      <div className="left-header">
        <Link to="/">
          <img className="logo" src={Logo} alt="Sit Lo Logo" />
        </Link>
        <SocialMedia />
      </div>
      <nav>
        <ul className="nav-list">
          <li>
            <Link className="nav-item" activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="nav-item" activeClassName="active" to="/menu">
              Menu
            </Link>
          </li>
          <li>
            <Link className="nav-item" activeClassName="active" to="/gallery">
              Gallery
            </Link>
          </li>
          {/* <li>
            <Link
              className="nav-item"
              activeClassName="active"
              to="/careers"
            >
              Careers
            </Link>
          </li>
          <li>
            <Link
              className="nav-item"
              activeClassName="active"
              to="/contact"
            >
              Contact
            </Link>
          </li> */}
          <OrderOnline />
        </ul>
      </nav>
    </header>
  )
}

export default Header
