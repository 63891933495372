import React, { useState } from "react"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { Link } from "gatsby"

const OrderOnline = () => {
  const [open, setOpen] = useState(false)
  const [openMobile, setOpenMobile] = useState(false)
  const [toast, setToast] = useState(false)
  const [toastTwo, setToastTwo] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const onOpenMobileModal = () => setOpenMobile(true)
  const onCloseMobileModal = () => setOpenMobile(false)
  const onOpenToast = () => setToast(true)
  const onCloseToast = () => {
    setOpen(false)
    setToast(false)
  }
  const onOpenToastTwo = () => setToastTwo(true)
  const onCloseToastTwo = () => {
    setOpen(false)
    setToastTwo(false)
  }

  const [spinner, setSpinner] = useState({
    loading: true,
  })

  const hideSpinner = () => {
    setSpinner({
      loading: false,
    })
  }

    return (
        <div className="order-online">
            <div className="order-online-desktop">
              <button onClick={onOpenModal}>Order Online</button>
              <Modal open={open} onClose={onCloseModal} center>
                  <div className="locations"><h3>Locations</h3>
                    <Link to="https://www.toasttab.com/sitlosaigon" target="_blank"><button>Elk Grove</button></Link>
                    <br/>
                    <Link to="https://www.toasttab.com/sit-lo-saigon-davis-424-g-street/v3/?mode=fulfillment" target="_blank"><button>Davis</button></Link>
                  </div>
              </Modal>
            {/* <Modal open={toast} onClose={onCloseToast} center>
            <iframe src="https://www.toasttab.com/sitlosaigon" className="order-frame"></iframe>
            </Modal>
            <Modal open={toastTwo} onClose={onCloseToastTwo} center>
            <iframe src="https://www.toasttab.com/sit-lo-saigon-davis-424-g-street/v3/?mode=fulfillment" className="order-frame"></iframe>
            </Modal> */}
            </div>
            <div className="order-online-mobile">
                <button onClick={onOpenMobileModal}>Order Online</button>
                <Modal open={openMobile} onClose={onCloseMobileModal} center>
                    <div className="locations"><h3>Locations</h3>
                    <Link to="https://www.toasttab.com/sitlosaigon" target="_blank"><button>Elk Grove</button></Link>
                    <br/>
                    <Link to="https://www.toasttab.com/sit-lo-saigon-davis-424-g-street/v3/?mode=fulfillment" target="_blank"><button>Davis</button></Link>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default OrderOnline
