import React from "react"
import { Parallax, Background } from 'react-parallax';

const ParallaxCard = ({ image, alt, children, height }) => {
	return (
		<Parallax strength={300}>
			<Background className="parallax-custom-bg">
				<img src={image} alt={alt} />
			</Background>
			<div className="parallax-wrapper" style={{ height: height }}>
				{children}
			</div>
		</Parallax>
	);
};

export default ParallaxCard;